@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App .widget-button{
  width: 200px;
  height: 50px;
  background-color: rgba(163, 163, 240, 0.699);;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border: none;
  border-radius: 10px;

}

.App .widget-button:hover{
  cursor: pointer;
  scale: 1.1;
  transition: 250ms;

}
.App .widget-button:active{
  cursor: pointer;
  scale: 0.9;
  transition: 250ms;

}

.App .support{
  width: 20%;
  height: 40%;
  background-color: rgba(163, 163, 240, 0.699);
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  
}
.App .url{
  width: 20%;
  height: 20%;
  background-color: rgba(163, 163, 240, 0.699);
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  
}

@media only screen and (max-width: 1248px){
  .App .url{
    width: 30%;
  }
}
@media only screen and (max-width: 1248px){
  .App .support{
    width: 30%;
  }
}
@media only screen and (max-width: 870px){
  .App .support{
    width: 40%;
  }
}
@media only screen and (max-width: 660px){
  .App .support{
    width: 50%;
  }
}
@media only screen and (max-width: 520px){
  .App .support{
    width: 60%;
    height: 45%;
  }
}
.App .url .cancel{
  display: flex;
  justify-content: end;
}
.App .support .cancel{
  border: none;
  display: flex;
  justify-content: space-between;
}

.App .support .cancel .fetching:hover{
  cursor: pointer;
}

.App .url .cancel button, .App .support .cancel button{
  background-color: inherit;
  border: none;
  padding: 2px;
}
.App .url .cancel button:hover, .App .support .cancel button:hover{
  cursor: pointer;
}

.App .url .input{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin: 2px;
  overflow-y: scroll;
}

.App .url .input input{
  border: solid 1px gray;
  border-radius: 5px;
  width: full;
  padding: 5px;
  margin: 2px;
}

.App .url .input input:focus{
  border: solid 2px gray;
  outline-offset: 0px;
  outline: none;
}

.App .url .input button{
  width: min-content;
  border: solid 1px gray;
  border-radius: 7px;
  padding: 5px;
  margin: 5px;
}
.App .url .input button:hover{
  cursor: pointer;
  scale: 1.1;
  transition: 250ms;
}
.App .url .input button:active{
  cursor: pointer;
  scale: 0.9;
  transition: 250ms;
}

.App .support .loading,.App .support .load{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.App .support .scrollable-result{
  overflow-y: scroll;
}

.App .support .result{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
}

.App .support .result .score{
  height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}


.App .support .result .otherData{
  height: fit-content;
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 15px;
  row-gap: 10px;
  margin-bottom: 10px;
}

.App .support .result .otherData .grid-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  text-align: center;
  border: solid 1px;
  border-radius: 10px;
}

.App .support .result .otherData .grid-item p{
  margin: 0px;
  padding: 2px;
}
/* width */
::-webkit-scrollbar {
  width: 7.5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}


